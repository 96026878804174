.master-card{
    width: 350px;
    height: 120px;
}
.fimg-size{
    width: 120px;
    height: 100px;
}

.text-position{
    text-align: center;
}